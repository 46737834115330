import { toNumber } from 'lodash'
import { LicenseKeyType } from 'types/licenseKeys'

/**
 * Checks if we can show ID of the license key
 * It can be shown if license key type is not one of those: Network, NodeLocked
 * @param {LicenseKey} licenseKey
 * @returns {Boolean}
 */
export const canShowLicenseKeyID = licenseKey => {
  if (!licenseKey) {
    return false
  }
  return licenseKey.type !== LicenseKeyType.NODE_LOCKED && licenseKey.type !== LicenseKeyType.NETWORK
}

/**
 * Finds the latest version from an array of version strings.
 *
 * @param {string[]} versions - An array of version strings (e.g., ["10.1.2", "10.0.10"]).
 * @returns {string} The latest version string.
 */
export const compareVersions = (versionA, versionB) => {
  const splitA = versionA.split('.').map(toNumber)
  const splitB = versionB.split('.').map(toNumber)

  for (let i = 0; i < Math.max(splitA.length, splitB.length); i++) {
    const numA = splitA[i] || 0
    const numB = splitB[i] || 0

    if (numA > numB) return 1
    if (numA < numB) return -1
  }
  return 0
}
