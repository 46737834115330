import { call, put, takeLatest, fork, select, takeEvery } from 'redux-saga/effects'
import { path, omit } from 'ramda'
import CompaniesActions from 'modules/companies/actions'
import { toast } from 'react-toastify'
import i18next from 'i18n'
import history from 'config/history'
import { routeError, routeUsers, routeCompanyUsers, routeExternalRedirect } from 'utils/routing' // routeExternalRedirect
import { showErrorMessage, getErrorMessage, getAuthentificationHeader, resolveTasks, getPaginationFromResponse, createQueryString } from 'utils/api'
import { getCompanyId } from 'utils/company'
import axios_ from 'axios'
import axios, { getLoggedInAsUser, setLoggedInAsUser, axiosLidarmill } from 'utils/axios'
import { getCookie } from 'utils/cookies'
import { register as registerUser } from '../utils'
// Local deps
import UsersActions, { UsersTypes } from './actions'
import { getLoggedUser } from './selectors'
import { getAddress } from 'utils/users'
import config, { isProductionEnvironment } from 'config'
import { getQueryString } from 'modules/companies/sagas'
import { iframeParams } from './utils'
import { getUserDeviceTimeZoneOffset } from 'utils/dateTime'

// Sagas
function * getUsers ({ excludeArray = [] }) {
  yield put(UsersActions.getUsersLoading())
  try {
    const { data: { data: users } } = yield call(axios.get,
      `/users${excludeArray.length > 0 ? `?exclude=${excludeArray.join(',')}` : ''}`)
    const { data: { data: nonCompanyUsers } } = yield call(axios.get,
      `/users?non_company_only=true`)
    yield put(UsersActions.getUsersSuccess(users, nonCompanyUsers))
  } catch (e) {
    yield put(UsersActions.getUsersFailure(getErrorMessage(e)))
  }
}

function * changePassword ({ id, oldPassword, newPassword, repeatedPassword }) {
  yield put(UsersActions.changePasswordLoading())
  try {
    yield call(axios.put, `/users/${id}/change_password`, {
      ...(typeof oldPassword !== 'undefined' && { old_password: oldPassword }),
      new_password: newPassword,
      repeated_password: repeatedPassword,
    })
    yield put(UsersActions.changePasswordSuccess())
    toast.success(i18next.t('toast.user.changePasswordSuccess'))
  } catch (e) {
    showErrorMessage(e)
    yield put(UsersActions.changePasswordFailure(getErrorMessage(e)))
  }
}

function * getMe () {
  yield put(UsersActions.getMeLoading())
  const externalRedirectUrl = yield select(state => state.users.get('externalRedirectUrl'))
  try {
    const { data: { data: user } } = yield call(axios.get, `/me`)
    const { data: { data: permissions } } = yield call(axios.get, `/users/${user.id}/account_permissions`)
    yield put(CompaniesActions.getChildCompanies(getCompanyId(user)))
    yield put(CompaniesActions.getCompanySystemTypes(getCompanyId(user)))
    yield put(CompaniesActions.getLoggedUserCompany(getCompanyId(user)))
    yield put(UsersActions.getMeSuccess(user, permissions))
  } catch (e) {
    if (!externalRedirectUrl) {
      yield put(UsersActions.logout())
      yield put(UsersActions.getMeFailure(getErrorMessage(e)))
    }
  }
}

function * getUser ({ payload }) {
  yield put(UsersActions.getUserLoading())
  try {
    const { token, id } = payload
    const { data } = yield call(axios.get, `/users/${id}`, {
      headers: getAuthentificationHeader(token),
    })
    yield put(UsersActions.getUserSuccess(data.user))
  } catch (e) {
    yield put(UsersActions.getUserFailure(getErrorMessage(e)))
  }
}

function * getActiveUser ({ user_id }) {
  yield put(UsersActions.getActiveUserLoading())
  try {
    const { data: { data: user } } = yield call(axios.get, `/users/${user_id}`)
    yield put(UsersActions.getActiveUserSuccess(user))
  } catch (e) {
    const status = e.response.status
    if (status >= 400 && status < 500) {
      history.push(routeError())
      if (status === 403) {
        toast.error('You are not allowed to see this page')
      } else {
        toast.error(i18next.t('toast.user.getActiveUserError'))
      }
    }
    yield put(UsersActions.getActiveUserFailure(getErrorMessage(e)))
  }
}

function * createUser ({ payload }) {
  yield put(UsersActions.createUserLoading())
  try {
    yield call(axios.post, '/users/create', payload)
    yield put(UsersActions.createUserSuccess())
    yield put(UsersActions.getUsers())
  } catch (e) {
    yield put(UsersActions.createUserFailure(getErrorMessage(e)))
  }
}

function * adminRegister ({ payload, onSuccess }) {
  yield put(UsersActions.registerLoading())
  try {
    const { is_active, system_types, is_crs_advanced, createdCompany } = payload
    const companyId = createdCompany && createdCompany.id
    const body = {
      email: payload.email,
      password: payload.password,
      first_name: payload.firstName,
      last_name: payload.lastName,
      /*
      company: {
        name: payload.company,
        customer_type: payload.customer_type,
        is_active: payload.company_is_active,
      },
      */
      country: payload.country,
      address: getAddress(payload),
      terms_accepted: payload.termsAccepted,
      // system_serial_numbers: getSystemSerialNumber(payload),
      send_set_password_email: payload.send_set_password_email,
      ...('website_access' in payload && { website_access: payload.website_access }),
      ...('fp_access' in payload && { fp_access: payload.fp_access }),
      ...('fp_end_date' in payload && { fp_end_date: payload.fp_end_date }),
    }
    // const responseData = yield call(registerUser, `/register/admin?lat=${payload.lat}&lon=${payload.lng}`, body, false, undefined, true)
    const responseData = yield call(registerUser, `/companies/${companyId}/users`, body, false, undefined, true)
    /*
    yield put(CompaniesActions.updateCompany(companyId, {
      customer_type: payload.customer_type,
      is_active: payload.company_is_active,
    }, false))
    */
    const user = responseData.data
    // Add system types after user created
    if (companyId && createdCompany.system_types.length <= 0 && system_types.length > 0) {
      const tasks = []
      for (let i = 0; i < system_types.length; i++) {
        const systemType = system_types[i]
        tasks.push(yield fork(axios.post, `/companies/${companyId}/system_types/${systemType.id}`, {}))
      }
      yield resolveTasks(tasks)
    }
    yield put(UsersActions.registerSuccess(user, true))
    // Update is_active and/or is_crs_advanced after user created
    if (is_active || is_crs_advanced) {
      yield put(UsersActions.updateUser({
        id: user.id,
        ...(is_crs_advanced && { is_crs_advanced }),
        ...(is_active && { is_active }),
      }, false))
    }
    if (typeof onSuccess === 'function') {
      onSuccess()
    }
    yield put(CompaniesActions.getCompanies())
  } catch (e) {
    showErrorMessage(e)
    yield put(UsersActions.registerFailure(getErrorMessage(e)))
  }
}

function * register ({ payload, createdByUser = false }) {
  yield put(UsersActions.registerLoading())
  try {
    const body = {
      email: payload.email,
      password: payload.password,
      first_name: payload.firstName,
      last_name: payload.lastName,
      company_name_register: payload.company,
      /*
      company: {
        name: payload.company,
        // url: payload.companyUrl,
        // industry: payload.industry,
        // phone: payload.phone,
        // invoice_address: payload.invoiceAddress,
      },
      */
      country: payload.country,
      address: getAddress(payload),
      // phone: payload.phone,
      // mapping_system_product: payload.mappingSystemProduct,
      // navigation_system_vendor: payload.navigationSystemVendor,
      time_zone_offset: getUserDeviceTimeZoneOffset(),
      // unit_system: payload.unitSystem,
      terms_accepted: payload.termsAccepted,
      purchased_from: payload.purchased_from,
      serial_numbers: payload.serial_numbers,
      recaptcha_token: payload.recaptcha_token,
      // system_serial_numbers: getSystemSerialNumber(payload),
    }
    const data = yield call(registerUser, `/register`, body, !createdByUser)
    yield put(UsersActions.registerSuccess(data.data, createdByUser))
  } catch (e) {
    showErrorMessage(e)
    yield put(UsersActions.registerFailure(getErrorMessage(e)))
  }
}
/**
 * Constructs the LidarMill URL to set the auth token.
 *
 * @param {Object} options - Options for setting the auth token.
 * @param {string} options.token - The authentication token.
 * @param {boolean} [options.setAsLoggedAs=false] - Flag to set as logged as a specific user.
 * @param {string} options.redirectURL - URL to redirect after setting the token.
 * @param {string} options.permission_type - Type of permission to set.
 * @returns {string} - The constructed URL to set the authentication token. (ex. https://app.lidarmill.com/set_token?token={token}&permission_type={permission_type}&redirect_url={redirectURL})
 */
export function getLidarmillURLToSetAuthToken (options) {
  const { token, setAsLoggedAs = false, url, urlBase = config.LIDARMILL_BASE, permission_type } = options
  return [
    // use LIDARMILL_BASE if urlBase (ex. "https://app.lidarmill.com") from options equals null.
    // Equals null when user logs into Accounts
    urlBase === null ? config.LIDARMILL_BASE : urlBase,
    [
      'set_token',
      [
        ('token' in options) ? getQueryString('token', token) : '',
        ('setAsLoggedAs' in options) ? getQueryString('logged_as_user', setAsLoggedAs ? '1' : '0') : '',
        ('permission_type' in options && permission_type !== null) ? getQueryString('permission_type', permission_type) : '',
        ('url' in options) ? getQueryString('redirect_url', url) : '',
      ].filter(Boolean).join('&'),
    ].join('?'),
  ].join('/')
}

/**
 * Applies a redirect to the specified URL, setting the auth token if needed.
 *
 * @param {Object} options - Options for applying the redirect.
 * @param {string} options.url - The URL to redirect to.
 */
export function applyRedirect (options) {
  const { url } = options
  const urlBase = [config.LIDARMILL_BASE, config.BAYER_BASE].find(baseurl => url.startsWith(baseurl))
  if (urlBase) {
    history.push(routeExternalRedirect(getLidarmillURLToSetAuthToken(options)))
  } else {
    history.push(routeExternalRedirect(url))
  }
}

/**
 * Sets the user token for the LIDARMILL and optionally handles redirection and success callbacks.
 *
 * @param {string} token - The token to set.
 * @param {Object} [options={}] - Optional settings.
 * @param {boolean} [options.setAsLoggedAs=false] - Whether to set the token as a logged-in user.
 * @param {string} [options.redirectURL] - URL to redirect to after setting the token.
 * @param {Function} [options.onSuccess=null] - Callback function to be called on successful token setting.
 * @param {string} [options.permission_type] - Type of permission to be set.
 */
// eslint-disable-next-line no-unused-vars
function setLidarmillToken (token, options = {}) {
  options.token = token
  const { url = '', onSuccess = null } = options
  const urlBase = url && [config.LIDARMILL_BASE, config.BAYER_BASE].find(baseurl => url.startsWith(baseurl))
  options.urlBase = urlBase
  const { display, width, height, hidden } = iframeParams
  const iframe = document.createElement('iframe')
  iframe.style.display = display
  iframe.width = width
  iframe.height = height
  iframe.hidden = hidden
  iframe.src = getLidarmillURLToSetAuthToken(omit(['url'], options))
  document.body.appendChild(iframe)
  const onMessage = event => {
    if (event.origin !== config.LIDARMILL_BASE || event.origin !== config.BAYER_BASE) {
      return
    }
    if (typeof event.data === 'string') {
      const action = event.data || ''
      if (action === 'token-set') {
        document.body.removeChild(iframe)
        if (onSuccess) {
          onSuccess()
        }
        if (url) {
          setTimeout(() => {
            applyRedirect(options)
          }, 500)
        }
        window.removeEventListener('message', onMessage)
      }
    }
  }
  window.addEventListener('message', onMessage, false)
}

/**
 * Removes cookies from lidarmill.com by creating and loading a hidden iframe.
 * The iframe is appended to the document body, loads the remove_cookies endpoint,
 * and is removed from the DOM after the request is completed.
 *
 */
export function removeLidarmillCookies () {
  window.location.href = `${config.LIDARMILL_BASE}/remove_cookies?redirectUrl=${encodeURIComponent(`${window.location.origin}/login`)}`
}
function * login ({ payload, redirectURL, dispatch }) {
  yield put(UsersActions.loginLoading())
  try {
    const { email, password, permission_type } = payload
    const api = axios_.create({
      baseURL: config.API_BASE,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
      },
    })
    api.interceptors.request.use(
      function (config) {
        config.headers.withCredentials = true
        return config
      },
      function (err) {
        console.error('api.interceptors.request')
        console.error(err)
        return Promise.reject(err)
      },
    )
    const remember2faCookie = getCookie(
      isProductionEnvironment()
        ? 'accounts_2fa_remember_me'
        : 'accounts_develop_2fa_remember_me',
    )
    const { data: { data } } = yield call(api.post, '/login', {
      email,
      password,
      remember_2fa_token: remember2faCookie,
      ...(permission_type && { permission_type }),
    })
    if ('2fa_required' in data) {
      yield put(UsersActions.setTwoFactorLogin(data.temp_auth_token))
    } else {
      yield put(UsersActions.loginSuccess(data.token, data.user, redirectURL))
      toast.success(i18next.t('toast.user.loginSuccess'))
      setLidarmillToken(data.token, { url: redirectURL, permission_type })
    }
  } catch (e) {
    console.error(e)
    console.error(getErrorMessage(e))
    const allowedToLoginAt = path(['data', 'data', 'allowed_to_login_at'], e.response)
    if (path(['response', 'status'], e) === 400 && path(['data', 'data', 'password_not_set'], e.response)) {
      yield put(UsersActions.loginFailure(getErrorMessage(e), true))
    } else {
      showErrorMessage(e)
      yield put(UsersActions.loginFailure(getErrorMessage(e), false, allowedToLoginAt))
    }
  }
}

function * loginAs ({ userId, loggedAs }) {
  yield put(UsersActions.loginAsLoading())
  try {
    const { data: { data } } = yield call(axios.get, `/users/${userId}/login_as`)
    toast.success(i18next.t('toast.user.loginSuccess'))
    const logged_as = getLoggedInAsUser()
    let loggedAsFinal = ''
    if (!logged_as) {
      loggedAsFinal = loggedAs
      setLoggedInAsUser(loggedAs)
    } else if (logged_as === data.user.email) {
      setLoggedInAsUser(loggedAsFinal)
    }
    yield put(UsersActions.loginAsSuccess(data.token, data.user, loggedAsFinal))
    setLidarmillToken(data.token, { setAsLoggedAs: Boolean(loggedAsFinal) })
  } catch (e) {
    console.error(e)
    console.error(getErrorMessage(e))
    showErrorMessage(e)
  }
}

function * twoFactorLogin ({ payload, redirectURL, dispatch }) {
  yield put(UsersActions.twoFactorLoginLoading())
  try {
    const { token, remember_2fa, permission_type } = payload
    const tempToken = yield select(state => state.users.get('tempToken'))
    const api = axios_.create({
      baseURL: config.API_BASE,
      withCredentials: true,
      headers: {
        'Content-Type': 'application/json',
      },
    })
    api.interceptors.request.use(
      function (config) {
        config.headers.withCredentials = true
        return config
      },
      function (err) {
        console.error('api.interceptors.request')
        console.error(err)
        return Promise.reject(err)
      },
    )
    const { data: { data } } = yield call(api.post, '/login/2fa', {
      token: token,
      temp_auth_token: tempToken,
      remember_2fa,
      ...(permission_type && { permission_type }),
    })
    yield put(UsersActions.twoFactorLoginSuccess(data.token, data.user, redirectURL))
    toast.success(i18next.t('toast.user.loginSuccess'))
    setLidarmillToken(data.token, { url: redirectURL, permission_type })
  } catch (e) {
    console.error(e)
    console.error(getErrorMessage(e))
    if (path(['response', 'status'], e) === 400 && path(['data', 'data', 'token_expired'], e.response)) {
      yield put(UsersActions.twoFactorLoginFailure(getErrorMessage(e), true))
      toast.success(i18next.t('toast.user.twoFactorAuthLoginError'))
    } else {
      showErrorMessage(e)
      yield put(UsersActions.twoFactorLoginFailure(getErrorMessage(e), false))
    }
  }
}

function * logout ({ url, callback }) {
  yield put(UsersActions.logoutLoading())
  try {
    yield call(axios.get, '/remove_auth_cookies', { withCredentials: true })
    yield put(UsersActions.logoutSuccess(url, callback))
  } catch (e) {
    yield put(UsersActions.logoutFailure(getErrorMessage(e)))
  }
}

function * requestRecovery ({ payload }) {
  yield put(UsersActions.requestRecoveryLoading())
  try {
    const { email } = payload
    yield call(axios.post, '/recovery', { email })
    yield put(UsersActions.requestRecoverySuccess())
    // history.push('/')
    toast.success(i18next.t('toast.user.requestRecoverySuccess'))
  } catch (e) {
    showErrorMessage(e)
    yield put(UsersActions.requestRecoveryFailure(getErrorMessage(e)))
  }
}

function * recover ({ payload }) {
  yield put(UsersActions.recoverLoading())
  try {
    const { token, password } = payload
    yield call(axios.post, `/recovery/${token}`, { password })
    toast.success(i18next.t('toast.user.recoverSuccess'))
    yield put(UsersActions.recoverSuccess())
  } catch (e) {
    showErrorMessage(e)
    yield put(UsersActions.recoverFailure(getErrorMessage(e)))
  }
}

function * confirmEmail ({ token }) {
  yield put(UsersActions.confirmEmailLoading())
  try {
    yield call(axios.post, `/email_confirmation/${token}`, {})
    yield put(UsersActions.confirmEmailSuccess())
  } catch (e) {
    showErrorMessage(e)
    yield put(UsersActions.confirmEmailFailure(getErrorMessage(e)))
  }
}

function * sendConfirmEmail ({ email }) {
  yield put(UsersActions.sendConfirmEmailLoading())
  try {
    yield call(axios.post, `/email_confirmation`, { email })
    yield put(UsersActions.sendConfirmEmailSuccess())
  } catch (e) {
    showErrorMessage(e)
    yield put(UsersActions.sendConfirmEmailFailure(getErrorMessage(e)))
  }
}

function * deleteUser ({ userId, user }) {
  yield put(UsersActions.deleteUserLoading())
  try {
    yield call(axios.delete, `/users/${userId}`)
    yield put(UsersActions.deleteUserSuccess(userId))
    toast.success(i18next.t('toast.user.deleteUserSuccess'))
    if (user) history.push(routeCompanyUsers(getCompanyId(user)))
    else history.push(routeUsers())
  } catch (e) {
    showErrorMessage(e)
    yield put(UsersActions.deleteUserFailure(getErrorMessage(e)))
  }
}

function * updateUser ({ payload, withSuccessMessage = true }) {
  yield put(UsersActions.updateUserLoading())
  try {
    const { id, ...payloadWithoutId } = payload
    const { data: { data: user } } = yield call(axios.post, `/users/${id}`, payloadWithoutId)
    if (withSuccessMessage) toast.success(i18next.t('toast.user.updateSuccess'))
    yield put(UsersActions.updateUserSuccess(user))
  } catch (e) {
    showErrorMessage(e)
    yield put(UsersActions.updateUserFailure(getErrorMessage(e)))
  }
}

function * acceptTerms () {
  try {
    const loggedUser = yield select(state => getLoggedUser(state))
    const { data: { data: user } } = yield call(axios.post, `/users/${loggedUser.id}`, {
      terms_accepted: true,
    })
    yield put(UsersActions.updateUserSuccess(user))
    yield put(UsersActions.setAcceptTermsFormOpen(false))
  } catch (e) {
    yield put(UsersActions.updateUserFailure(getErrorMessage(e)))
  }
}

function * getSubscriptions ({ userId }) {
  yield put(UsersActions.getSubscriptionsLoading(userId))
  try {
    const { data: { data: subscriptions } } = yield call(axios.get, `/users/${userId}/subscriptions`)
    yield put(UsersActions.getSubscriptionsSuccess(userId, subscriptions))
  } catch (e) {
    yield put(UsersActions.getSubscriptionsFailure(userId))
  }
}

function * updateSubscription ({ companyId, subscriptionId, payload, onSuccess }) {
  yield put(UsersActions.updateSubscriptionLoading(companyId, subscriptionId))
  try {
    const { data: { data: updatedSubscription } } = yield call(axios.put, `/subscriptions/${subscriptionId}`, payload)
    yield put(UsersActions.updateSubscriptionSuccess(companyId, subscriptionId, updatedSubscription))
    toast.success(i18next.t('toast.user.updateSubscriptionSuccess'))
    if (typeof onSuccess === 'function') {
      onSuccess()
    }
  } catch (e) {
    showErrorMessage(e)
    yield put(UsersActions.updateSubscriptionFailure(companyId, subscriptionId))
  }
}

function * getUserPermissions ({ userId }) {
  yield put(UsersActions.getUserPermissionsLoading(userId))
  try {
    const { data: { data: permissions } } = yield call(axios.get, `/users/${userId}/permissions`)
    yield put(UsersActions.getUserPermissionsSuccess(userId, permissions))
  } catch (e) {
    yield put(UsersActions.getUserPermissionsFailure(userId))
  }
}
// Get two-factor authentication qr code and key
function * getTwoFactorAuth ({ userId }) {
  yield put(UsersActions.getTwoFactorAuthLoading(userId))
  try {
    const { data: { data: qrcode } } = yield call(axios.get, `/2fa/qrcode`)
    yield put(UsersActions.getTwoFactorAuthSuccess(userId, qrcode.qr_svg, qrcode.key))
  } catch (e) {
    yield put(UsersActions.getTwoFactorAuthFailure(userId))
  }
}
// Activate two-factor authentication for user
function * activateTwoFactorAuth ({ userId, pin, key }) {
  yield put(UsersActions.activateTwoFactorAuthLoading(userId))
  try {
    const { data: { data: user } } = yield call(axios.post, `/users/${userId}/2fa/activate`, {
      token: pin,
      key,
    })
    yield put(UsersActions.activateTwoFactorAuthSuccess(userId, user))
    yield put(UsersActions.getMe())
    toast.success('Two-factor authentication has been activated!')
  } catch (e) {
    showErrorMessage(e)
    yield put(UsersActions.activateTwoFactorAuthFailure(userId))
  }
}
// Activate two-factor authentication for user
function * deactivateTwoFactorAuth ({ userId }) {
  yield put(UsersActions.deactivateTwoFactorAuthLoading(userId))
  try {
    const { data: { data: user } } = yield call(axios.post, `/users/${userId}`, { use_2fa: false })
    yield put(UsersActions.deactivateTwoFactorAuthSuccess(userId, user))
    yield put(UsersActions.getMe())
    toast.success('Two-factor authentication has been deactivated!')
  } catch (e) {
    showErrorMessage(e)
    yield put(UsersActions.deactivateTwoFactorAuthFailure(userId))
  }
}

// Get user's db logs
function * getUserDBLogs ({ userId, pagination, onSuccess }) {
  const { search = '', pageSize = 100, page = 1, orderBy = 'timestamp', order = 'desc' } = pagination
  yield put(UsersActions.getUserDBLogsLoading())
  try {
    const { data: { data: logs, pagination } } = yield call(axios.get, `/users/${userId}/db_logs${createQueryString({ search, pageSize, page, order, orderBy })}`)
    yield put(UsersActions.getUserDBLogsSuccess(userId, logs, getPaginationFromResponse(pagination.total, page, pageSize)))
    if (onSuccess) {
      onSuccess()
    }
  } catch (e) {
    showErrorMessage(e)
    yield put(UsersActions.getUserDBLogsFailure(e.message || e))
  }
}

function * getUserReleases () {
  yield put(UsersActions.getUserReleasesLoading())
  try {
    const loggedUser = yield select(state => getLoggedUser(state))
    if (!loggedUser) {
      throw new Error('User is not logged in')
    }
    const { data: { data: user } } = yield call(axios.get, `/users/${loggedUser.id}/products/releases`)
    yield put(UsersActions.getUserReleasesSuccess(user))
  } catch (e) {
    yield put(UsersActions.getUserReleasesFailure(getErrorMessage(e)))
  }
}

function * setUserSeenReleases ({ productName, numberOfReleases, onSuccess }) {
  yield put(UsersActions.setUserSeenReleasesLoading())
  try {
    const loggedUser = yield select(state => getLoggedUser(state))
    if (!loggedUser) {
      throw new Error('User is not logged in')
    }
    yield call(axios.post, `/users/${loggedUser.id}/products/${productName}/releases`, { last_seen: new Date().toISOString() })
    yield put(UsersActions.setUserSeenReleasesSuccess(productName, numberOfReleases))
    if (onSuccess) {
      onSuccess()
    }
  } catch (e) {
    yield put(UsersActions.setUserSeenReleasesFailure(getErrorMessage(e)))
  }
}

function * getUserProjects ({ userId }) {
  yield put(UsersActions.getUserProjectsLoading(userId))
  try {
    const { data: { data: projects } } = yield call(axiosLidarmill.get, `/users/${userId}/projects?sort=created`)
    yield put(UsersActions.getUserProjectsSuccess(userId, projects))
  } catch (e) {
    yield put(UsersActions.getUserProjectsFailure(userId, getErrorMessage(e)))
  }
}

// Watchers
function * getUsersWatcher () {
  yield takeLatest(UsersTypes.GET_USERS, getUsers)
}

function * getMeWatcher () {
  yield takeLatest(UsersTypes.GET_ME, getMe)
}

function * getUserWatcher () {
  yield takeLatest(UsersTypes.GET_USER, getUser)
}

function * getActiveUserWatcher () {
  yield takeLatest(UsersTypes.GET_ACTIVE_USER, getActiveUser)
}

function * createUserWatcher () {
  yield takeLatest(UsersTypes.CREATE_USER, createUser)
}

function * loginWatcher () {
  yield takeLatest(UsersTypes.LOGIN, login)
}

function * loginAsWatcher () {
  yield takeLatest(UsersTypes.LOGIN_AS, loginAs)
}

function * twoFactorLoginWatcher () {
  yield takeLatest(UsersTypes.TWO_FACTOR_LOGIN, twoFactorLogin)
}

function * registerWatcher () {
  yield takeLatest(UsersTypes.REGISTER, register)
}

function * adminRegisterWatcher () {
  yield takeLatest(UsersTypes.ADMIN_REGISTER, adminRegister)
}

function * logoutWatcher () {
  yield takeLatest(UsersTypes.LOGOUT, logout)
}

function * requestRecoveryWatcher () {
  yield takeLatest(UsersTypes.REQUEST_RECOVERY, requestRecovery)
}

function * recoverWatcher () {
  yield takeLatest(UsersTypes.RECOVER, recover)
}

function * confirmEmailWatcher () {
  yield takeLatest(UsersTypes.CONFIRM_EMAIL, confirmEmail)
}

function * sendConfirmEmailWatcher () {
  yield takeLatest(UsersTypes.SEND_CONFIRM_EMAIL, sendConfirmEmail)
}

function * deleteUserWatcher () {
  yield takeLatest(UsersTypes.DELETE_USER, deleteUser)
}

function * updateUserWatcher () {
  yield takeLatest(UsersTypes.UPDATE_USER, updateUser)
}

function * changePasswordWatcher () {
  yield takeLatest(UsersTypes.CHANGE_PASSWORD, changePassword)
}

function * acceptTermsWatcher () {
  yield takeLatest(UsersTypes.ACCEPT_TERMS, acceptTerms)
}

function * getSubscriptionsWatcher () {
  yield takeEvery(UsersTypes.GET_SUBSCRIPTIONS, getSubscriptions)
}

function * updateSubscriptionWatcher () {
  yield takeLatest(UsersTypes.UPDATE_SUBSCRIPTION, updateSubscription)
}

function * getUserPermissionsWatcher () {
  yield takeLatest(UsersTypes.GET_USER_PERMISSIONS, getUserPermissions)
}

function * getTwoFactorAuthWatcher () {
  yield takeLatest(UsersTypes.GET_TWO_FACTOR_AUTH, getTwoFactorAuth)
}

function * activateTwoFactorAuthWatcher () {
  yield takeLatest(UsersTypes.ACTIVATE_TWO_FACTOR_AUTH, activateTwoFactorAuth)
}

function * deactivateTwoFactorAuthWatcher () {
  yield takeLatest(UsersTypes.DEACTIVATE_TWO_FACTOR_AUTH, deactivateTwoFactorAuth)
}

function * getUserDBLogsWatcher () {
  yield takeLatest(UsersTypes.GET_USER_DB_LOGS, getUserDBLogs)
}

function * getUserReleasesWatcher () {
  yield takeLatest(UsersTypes.GET_USER_RELEASES, getUserReleases)
}

function * setUserSeenReleasesWatcher () {
  yield takeLatest(UsersTypes.SET_USER_SEEN_RELEASES, setUserSeenReleases)
}

function * getUserProjectsWatcher () {
  yield takeLatest(UsersTypes.GET_USER_PROJECTS, getUserProjects)
}

export default function * root () {
  yield fork(getUsersWatcher)
  yield fork(getUserWatcher)
  yield fork(getMeWatcher)
  yield fork(createUserWatcher)
  yield fork(loginWatcher)
  yield fork(loginAsWatcher)
  yield fork(twoFactorLoginWatcher)
  yield fork(registerWatcher)
  yield fork(adminRegisterWatcher)
  yield fork(logoutWatcher)
  yield fork(requestRecoveryWatcher)
  yield fork(recoverWatcher)
  yield fork(confirmEmailWatcher)
  yield fork(sendConfirmEmailWatcher)
  yield fork(deleteUserWatcher)
  yield fork(getActiveUserWatcher)
  yield fork(updateUserWatcher)
  yield fork(changePasswordWatcher)
  yield fork(acceptTermsWatcher)
  yield fork(getSubscriptionsWatcher)
  yield fork(updateSubscriptionWatcher)
  yield fork(getUserPermissionsWatcher)
  yield fork(getTwoFactorAuthWatcher)
  yield fork(activateTwoFactorAuthWatcher)
  yield fork(deactivateTwoFactorAuthWatcher)
  yield fork(getUserDBLogsWatcher)
  yield fork(getUserReleasesWatcher)
  yield fork(setUserSeenReleasesWatcher)
  yield fork(getUserProjectsWatcher)
}
