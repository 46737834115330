import { UsersTypes } from 'modules/users/actions'

function redirectAfterLogout (action) {
  if (action.type === UsersTypes.LOGOUT_SUCCESS) {
    const { callback } = action
    if (typeof callback === 'function') {
      callback()
    }
  }
}

export const redirectMiddleware = store => next => action => {
  next(action)
  redirectAfterLogout(action)
}
