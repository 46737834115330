import { Map } from 'immutable'
import { getDefaultPagination } from 'modules/utils'
import { CheckoutSessionState } from 'utils/billingAgreement'

export const INITIAL_STATE = Map({
  invoices: {},
  cards: {},
  grants: {},
  extendEvents: {},
  extendSubscriptionIsLoading: false,
  cancelEvents: {},
  getSubscriptionCancelEventsIsLoading: false,
  getSubscriptionCancelEventsFailureMessage: {},
  getPlanGrantsIsLoading: {},
  planGrants: {},
  createPlanGrantIsLoading: false,
  deletePlanGrantIsLoading: false,
  licenseKeys: {},
  licenseKeyAttachments: {},
  licenseKeyAddedEvents: {},
  getLicenseKeyAttachmentsIsLoading: {},
  getLicenseKeyAddedEventsIsLoading: {},
  addKeyIsLoading: false,
  grantTypes: [],
  getSubscriptionInvoicesIsLoading: {},
  getSubscriptionCardIsLoading: {},
  getSubscriptionExtendEventsIsLoading: {},
  getSubscriptionCardFailureMessage: {},
  getSubscriptionExtendEventsFailureMessage: {},
  updateSubscriptionCardIsLoading: {},
  getSubscriptionGrantsIsLoading: {},
  stripeRefundIsLoading: {},

  salesProducts: [],
  salesBundles: [],
  // used to find a sales product based on the given duration
  durationsMap: [],
  durationTypes: [],
  salesProductsTypes: [],
  getSalesProductsIsLoading: false,
  getSalesProductsErrorMessage: null,
  stripePlanToken: null,
  stripePlanState: false,

  subscribed: false,
  subscriptionType: null,
  paypalSuccess: false,
  paymentToken: null,
  getBillingAgreementIsLoading: false,
  getBillingAgreementErrorMessage: null,
  getBillingAgreementForActiveUserIsLoading: false,
  getBillingAgreementForActiveUserErrorMessage: null,
  createPayPalBillingAgreementIsLoading: false,
  createPayPalBillingAgreementErrorMessage: null,
  executePayPalBillingAgreementIsLoading: false,
  executePayPalBillingAgreementErrorMessage: null,
  executeStripeBillingAgreementIsLoading: false,
  executeStripeBillingAgreementErrorMessage: null,
  // Dialogs
  cancelSubscriptionId: null,
  cancelSubscriptionCompanyId: null,
  isStripeFormDialogOpened: false,
  stripeDialogState: null,
  isStripeCancelDialogOpened: false,

  activateOfflineSubscriptionIsLoading: {},
  deactivateOfflineSubscriptionIsLoading: {},
  transferSubscriptionIsLoading: false,
  getSubscriptionLicenseKeysIsLoading: {},

  allSubscriptions: [],
  allSubscriptionsPagination: getDefaultPagination({ page_size: 50, order_by: 'end_date', order: 'desc' }),
  allSubscriptionsLoading: false,

  // Used for checking checkout session
  checkoutSessionId: null,
  checkoutSessionType: null,
  checkoutSessionIsLoading: false,
  lastCheckoutSession: null,
  checkoutSessionState: {
    text: '',
    timeout: 5 * 1000,
    status: CheckoutSessionState.PENDING,
    id: null,
    type: null,
    attempts: 0,
  },
  retryPaymentState: {
    text: '',
    timeout: 5 * 1000,
    status: CheckoutSessionState.PENDING,
    id: null,
    type: null,
    attempts: 0,
  },
  cancelSubscriptionIsLoading: {},
  deactivateSubscriptionState: {},
  deactivateSubscriptionFailureMessage: {},
  getSubscriptionDBLogsLoading: {},
  subscriptionLogs: {},
  getSubscriptionDBLogsPagination: {},
})
